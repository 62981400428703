<template>
  <List></List>
</template>

<script>
import List from "@/views/product/List.vue";
export default {
  name: "Sale",
  components: {
    List,
  },
};
</script>

<style scoped></style>
